import mixpanel from 'mixpanel-browser';

class Mixpanel {
    instance = null;
    constructor() {
        if (!this.instance) {
            this.instance = mixpanel;
            mixpanel.init(process.env.REACT_APP_MIXPANEL_API_TOKEN, { debug: true });
        }
    }
    track(event, data, extra = false) {
        mixpanel.track(event, { ...data })
    }
}

const mixpanelObj = new Mixpanel();
Object.freeze(mixpanelObj);

export const EVENTS = {
    "VISITED": "VISITED"
}

export const handleMixpanelEvents = (event, data) => {
    switch (event) {
        case EVENTS.VISITED:
            mixpanelObj.track(event, data);
            break;
    }
}