import React from "react";
import { io } from "socket.io-client";

const SOCKET_SERVER_URL = process.env.REACT_APP_BACKEND_URL;

const useSocket = () => {
    const [socket] = React.useState(() => io(SOCKET_SERVER_URL));
    return socket;
}

export default useSocket;
